<template>
  <fragment>
    <div class="page-container">
      <!-- <div class="guess-need" v-if="cnList.length">
          <div class="guess-need-title">猜你需要:</div>
          <a-row class="guess-need-list" :gutter="[15, 5]">
            <a-col :span="8" class="guess-need-item" v-for="(item, index) in cnList" :key="index">
              <a class="cursor-pointer block line-ellipsis" @click="gnClick(item)" :title="item.mName">
                {{ item.mName }}
              </a>
            </a-col>
          </a-row>
        </div> -->
      <div class="menu-list" ref="bricksContainer">
        <bricks ref="bricks" :data="displayMenu" :sizes="sizes">
          <template slot-scope="{ item }">
            <div
              class="menu"
              :class="{ collapse: item.collapse }"
              :style="{ width: width + 'px', 'border-top-color': item.color }"
            >
              <div class="menu-hd">
                <a-icon type="appstore-o" class="mr-2" :style="{ color: item.color }"></a-icon>
                {{ item.meta.title }}
              </div>
              <div class="menu-bd" :ref="'mbd-' + item.meta.code">
                <div class="menu-l2-list" v-for="l2 in getChildren(item)" :key="l2.path">
                  <div class="menu-l2">
                    <div
                      class="menu-l2-title"
                      @click="l2Click(l2)"
                      :class="{ 'cursor-pointer': l2.meta.url, 'no-show': l2.meta.title === '_HIDDEN_' }"
                    >
                      {{ l2.meta.title }}
                    </div>
                    <div class="menu-l3-list">
                      <div class="menu-l3" @click="menuClick(l3)" v-for="l3 in l2.children" :key="l3.path">
                        <a-tooltip placement="bottomLeft" :title="l3.meta.extra.remark">
                          <span v-html="getHtml(l3)"></span>
                        </a-tooltip>
                        <div
                          class="action"
                          :class="{ block: collectionMenuIds.includes(l3.meta.extra.id) }"
                          @click.stop="changeCollect(l3)"
                        >
                          <a-icon
                            type="star"
                            :theme="collectionMenuIds.includes(l3.meta.extra.id) ? 'filled' : 'outlined'"
                          ></a-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="menu-ft cursor-pointer" @click="collapse(item)" v-if="showCollapseBtn(item)">
                <a-icon class="collapse-btn" type="double-right"></a-icon>
              </div>
            </div>
          </template>
        </bricks>
      </div>
    </div>
    <ToolsSearch @input="search"></ToolsSearch>
  </fragment>
</template>

<script>
import { menuNavigate, open } from '@/service/navigate'
import Bricks from 'vue-bricks'
import _ from 'lodash'
import treeUtils from '@/utils/treeUtils'
import ToolsSearch from '@/components/ToolsSearch'

let menu = null
export default {
  data() {
    return {
      // 筛选展示数据
      displayMenu: [],
      cnList: [],
      sizes: [
        { columns: 2, gutter: 20 },
        { mq: '1536px', columns: 3, gutter: 20 },
      ],
      width: 0,
      searchText: '',
    }
  },
  components: {
    Bricks,
    ToolsSearch,
  },
  computed: {
    collectionMenuIds() {
      return this.$store.state.user.collectionMenuIds
    },
  },
  watch: {
    '$store.state.app.sidebarCollapse'() {
      this.setWidth()
    },
  },
  async mounted() {
    await this.loadCnList()
    const workbenchMenu = this.$store.state.permission.menus.find((item) => item.path === '/m/workbench')
    menu = workbenchMenu ? workbenchMenu.children : []
    const colors = [
      'rgb(85, 210, 212)',
      'rgb(179, 123, 250)',
      'rgb(141, 206, 54)',
      'rgb(255, 198, 46)',
      'rgb(55, 178, 255)',
      'rgb(255, 94, 86)',
      'rgb(255, 149, 55)',
    ]
    menu.map((item, index) => {
      item.color = colors[index]
      item.collapse = false
    })
    this.displayMenu = menu

    this.setWidth()
    window.addEventListener('resize', this.setWidth)
  },
  destroyed() {
    menu = null
    window.removeEventListener('resize', this.setWidth)
  },
  methods: {
    getHtml(menu) {
      const title = menu.meta.title
      return this.searchText
        ? title.replace(this.searchText, `<span style="color:#639bfc">${this.searchText}</span>`)
        : title
    },
    collapse(l1) {
      // 只展开该菜单当前搜索
      const index = this.displayMenu.findIndex((m) => m.meta.extra.id === l1.meta.extra.id)
      // 一直展开
      // const menuIndex = menu.findIndex((m) => m.meta.extra.id === l1.meta.extra.id)
      // menu[menuIndex].collapse = true

      const displayMenu = [...this.displayMenu]
      displayMenu[index].collapse = true
      this.displayMenu = displayMenu
    },
    getCount(l1) {
      const countArr = []
      l1.children &&
        l1.children.forEach((l2) => {
          let count = 0
          l2.children &&
            l2.children.forEach((l3) => {
              count++
            })
          countArr.push(count)
        })
      return countArr
    },
    getChildren(l1) {
      if (l1.collapse) {
        return l1.children || []
      }
      const countArr = this.getCount(l1)
      let sliceIndex = 0
      let total = 0
      for (let i = 0; i < countArr.length; i++) {
        total += 2
        total += countArr[i]
        // 标题 2，普通 1 ，高30。总高需要获取到660 22*30
        if (total > 22) {
          sliceIndex = i + 1
          break
        }
      }
      if (sliceIndex === 0) {
        sliceIndex = l1.children.length
      }
      return l1.children?.slice(0, sliceIndex) || []
    },
    showCollapseBtn(l1) {
      if (l1.collapse) {
        return false
      }
      return l1.open
    },
    async changeCollect(m) {
      if (!this.collectionMenuIds.includes(m.meta.extra.id)) {
        await this.$axios.post('/unify-home/home/menu/saveJson', {
          mid: m.meta.extra.id,
        })
        this.$message.success('收藏成功')
      } else {
        await this.$axios.post('/unify-home/home/menu/delete', {
          mid: m.meta.extra.id,
        })
        this.$message.success('已取消')
      }

      this.$store.dispatch('UpdateCollectionMenus')
    },
    setWidth: _.debounce(async function () {
      // 默认值
      let gutterWidth = 0
      let columns = 0
      const containerWidth = this.$refs.bricksContainer?.offsetWidth || 0
      for (let i = this.sizes.length - 1; i >= 0; i--) {
        const mq = this.sizes[i].mq ? parseInt(this.sizes[i].mq) : 0
        if (window.innerWidth > mq) {
          gutterWidth = (this.sizes[i].columns - 1) * this.sizes[i].gutter
          columns = this.sizes[i].columns
          break
        }
      }

      this.width = (containerWidth - gutterWidth) / columns
      await this.$nextTick()
      this.$refs.bricks?.pack()
      await this.$nextTick()
      this.setOpenMenu()
    }, 300),
    setOpenMenu() {
      this.displayMenu.forEach((l1, index) => {
        const el = this.$refs[`mbd-${l1.meta.code}`]
        if (el.offsetHeight >= 720) {
          this.$set(this.displayMenu[index], 'open', true)
        } else {
          this.$set(this.displayMenu[index], 'open', false)
        }
      })
    },
    async loadCnList() {
      const res = await this.$axios.post('/unify-home/home/menu/cnlist')
      this.cnList = res.data
    },
    updateSearch() {
      this.$axios
        .post('/unify-home/home/menu/search', {
          key: this.searchText,
        })
        .then(() => {
          this.loadCnList()
        })
    },
    search: _.debounce(function (searchText) {
      let resultMenus = menu
      this.searchText = searchText
      if (this.searchText) {
        resultMenus = treeUtils.filter(
          menu,
          (m, indexId) => {
            const indexIdArr = indexId.split('-')
            return indexIdArr.length === 3 && m.meta.title.includes(this.searchText)
          },
          { root: true, leaf: true }
        )
        this.updateSearch()
      }

      this.displayMenu = resultMenus
      this.setWidth()
    }, 500),
    l2Click(menu) {
      if (menu.meta.url) {
        menuNavigate(menu)
      }
    },
    menuClick(menu) {
      menuNavigate(menu)
    },
    gnClick(gn) {
      open(gn.mUrl)
    },
  },
}
</script>

<style lang="less" scoped>
.page-container {
  width: 100%;
  position: absolute;
  overflow: auto;
  top: 100px;
  bottom: 0;
  left: 0;
  padding: 0px 30px 30px;
}
.menu {
  background: #ffffff;
  box-shadow: 0px 6px 10px 0px rgba(99, 155, 252, 0.05);
  border-radius: 4px;
  border-top: 5px solid;
  overflow: hidden;
  max-height: 735px;
  &.collapse {
    max-height: none;
  }
  .menu-hd {
    font-size: 18px;
    color: #30425b;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }
  .menu-bd {
    padding: 0 0 30px;
    margin-top: -15px;
  }
  .menu-l2-list {
    .menu-l2-title {
      margin-top: 30px;
      height: 30px;
      line-height: 30px;
      color: #30425b;
      font-size: 14px;
      padding: 0 15px;
      &.no-show {
        visibility: hidden;
        height: 0;
      }
    }
  }
  .menu-l3-list {
    .menu-l3 {
      color: #7a7a7a;
      font-size: 12px;
      position: relative;
      padding: 0 30px;
      line-height: 30px;
      // display: flex;
      // align-items: center;
      cursor: pointer;
      &:hover {
        color: #639bfc;
        background: #eee;
        &:before {
          background: #639bfc;
          border: 0;
        }
        .action {
          display: block;
        }
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        border-radius: 50%;
        top: 12px;
        left: 15px;
        width: 8px;
        height: 8px;
        border: 2px solid #a0afc7;
      }
      .action {
        display: none;
        position: absolute;
        padding: 0 10px;
        right: 0;
        top: 0px;
      }
    }
  }
  .menu-ft {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    z-index: 10;
    height: 30px;
    .collapse-btn {
      transform: rotate(90deg);
    }
  }
}
</style>
